<template>
    <div class='pa-5'>
        <v-card v-if='data'>
            <v-card-title>
                <h1 class='title'>{{ 'Departments' | term }}</h1>
                <v-btn small color='secondary' class='ml-3' @click='create()' v-if='!!selectedMineArea'>
                    <v-icon left size='medium'>mdi-plus</v-icon>
                    Add
                </v-btn>
                <v-spacer></v-spacer>
                <v-select
                    :items='mineAreas'
                    item-value='id'
                    item-text='name'
                    v-model='selectedMineArea'
                    :label="$termSync('Mine Area')"
                    single-line
                    hide-details
                    class='admin-table-filter'
                    :menu-props='{ dark: true }'
                ></v-select>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers='headers'
                :items='filtered'
                v-if='!!selectedMineArea'
            >
                <template v-slot:item.id='{ item }'>
                    <v-btn text icon @click='edit(item)' class='float-right'>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text icon @click='editSubscribers(item)' class='float-right'>
                        <v-icon>mdi-bell-plus</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <empty-state
                v-if='!selectedMineArea'
                icon='mdi-alert-circle-outline'
                :message="'Select a ' + $termSync('Mine Area')"
            ></empty-state>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if='selected.id'>Edit</span>
                <span v-else>Create</span>
                <span class='ml-2'>{{ 'Department' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref='form' :lazy-validation='true'>
                    <v-container fluid>
                        <v-row>
                            <v-col cols='12' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.name'
                                    counter='50'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Location Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.locationName'
                                    counter='50'
                                    hint='e.g. Heading'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Location Name (plural)'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.locationPluralName'
                                    counter='50'
                                    hint='e.g. Headings'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Location Name (abbreviation)'
                                    :rules='[$rules.required(), $rules.maxLength(5)]'
                                    v-model='selected.locationAbbrName'
                                    counter='5'
                                    hint="e.g. 'HEAD'"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="selected.id == null">
                            <v-col cols='4' class='pa-2'>
                                <v-select
                                    autocomplete='off'
                                    multiple
                                    label='Intended Use'
                                    :rules='[$rules.required()]'
                                    v-model='selected.intendedUses'
                                    :items='boardIntendedUses'
                                    item-value='id'
                                    item-text='name'
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Cycle Name'
                                    :rules='[$rules.maxLength(50)]'
                                    v-model='selected.cycleName'
                                    counter='50'
                                    hint='e.g. Cut'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Cycle Name (plural)'
                                    :rules='[$rules.maxLength(50)]'
                                    v-model='selected.cyclePluralName'
                                    counter='50'
                                    hint='e.g. Cuts'
                                ></v-text-field>
                            </v-col>
                            <v-col cols='4' class='pa-2'></v-col>
                        </v-row>
                        <v-row v-if='!filteredTasks.length'>
                            <v-col cols='12'>
                                <v-alert color='grey lighten-2'>
                                    <strong>Note:</strong> Departments require some specific configuration based on
                                    tasks. No tasks exist for this department yet. Configure tasks and then update
                                    this department
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if='filteredTasks.length'>
                            <v-col cols='4' class='pa-2'>
                                <v-checkbox
                                    autocomplete='off'
                                    label='SoS Tasks?'
                                    v-model='selected.hasStartOfShiftSpecialTasks'
                                ></v-checkbox>
                            </v-col>
                            <v-col cols='4' class='pa-2' v-if='selected.hasStartOfShiftSpecialTasks'>
                                <div v-for='(task, index) in selected.startOfShiftTaskTypesModel' :key='index'>
                                    <v-select
                                        autocomplete='off'
                                        label='Task Type'
                                        v-model='selected.startOfShiftTaskTypesModel[index]'
                                        :items='filteredTasks'
                                        item-value='id'
                                        item-text='name'
                                        hide-details
                                        append-outer-icon='mdi-delete'
                                        @click:append-outer='selected.startOfShiftTaskTypesModel.splice(index, 1)'
                                    ></v-select>
                                </div>
                                <v-btn class='mt-3' @click='selected.startOfShiftTaskTypesModel.push(null)'
                                >Add
                                </v-btn
                                >
                            </v-col>
                            <v-col cols='4' class='pa-2'></v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-checkbox
                                    autocomplete='off'
                                    label='EoS Task?'
                                    v-model='selected.hasEndOfShiftSpecialTask'
                                ></v-checkbox>
                            </v-col>
                            <v-col cols='4' class='pa-2'>
                                <v-select
                                    autocomplete='off'
                                    label='Task Type'
                                    :disabled='!selected.hasEndOfShiftSpecialTask'
                                    :rules='[$rules.requiredIf(() => selected.hasEndOfShiftSpecialTask)]'
                                    v-model='selected.endOfShiftTaskType'
                                    :items='filteredTasksForEoSTask'
                                    item-value='id'
                                    item-text='name'
                                    hide-details
                                ></v-select>
                            </v-col>
                            <v-col cols='4' class='pa-2'></v-col>
                        </v-row>
                        <v-subheader>Config</v-subheader>
                        <v-row>
                            <v-col cols='12' class='pa-2'>
                                <v-checkbox autocomplete='off' label='Enforce Cycles on Development Board?' :input="!selected.allowDevelopmentManualTasks" @input="(val)=>selected.allowDevelopmentManualTasks=!val"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <subscribers-dialog
            ref="subscribersDialog"
        >

        </subscribers-dialog>
        <confirm ref='confirm'></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import Departments from '@/lib/data/Departments';
import MineAreas from '@/lib/data/MineAreas';
import TaskTypes from '@/lib/data/TaskTypes';
import SubscribersDialog from '@/components/Admin/SubscribersDialog.vue';
import { IntendedBoardUse, TaskClasses } from '@/models/api';
import { enumOptions } from '@/lib/EnumExtensions';

export default {
    components: { SubscribersDialog },
    data() {
        return {
            data: null,
            mineAreas: null,
            selectedMineArea: null,
            showModal: false,
            selected: null,
            boardIntendedUses: enumOptions(IntendedBoardUse, this.$options.filters.intendedBoardUses),
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Short Code', value: 'short' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {
    },
    methods: {
        async loadData() {
            this.mineAreas = await MineAreas.get();
            this.data = await Departments.get();
            for (let x of this.data) {
                x.short = x.name[0];
            }

            this.taskTypes = await TaskTypes.get();
        },
        create() {
            this.edit({ mineAreaId: this.selectedMineArea, startOfShiftTaskTypes: '[]' });
        },
        edit(item) {
            this.selected = { ...item };
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async editSubscribers(item) {
            await this.$refs.subscribersDialog.show(
                async () => {
                    return await Departments.getSubscribersById(item.id);
                },
                async (subscribers) => {
                    await Departments.updateSubscribers({
                        _type: 'UpdateDepartmentNotificationSubscribersCommand',
                        departmentId: item.id,
                        subscribers: subscribers
                    });
                });
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.selected.id == null) {
                await Departments.create({
                    _type: 'CreateDepartmentCommand',
                    mineAreaId: this.selectedMineArea,
                    name: this.selected.name,
                    locationName: this.selected.locationName,
                    locationPluralName: this.selected.locationPluralName,
                    locationAbbrName: this.selected.locationAbbrName,
                    intendedUses: this.selected.intendedUses,
                    cycleName: this.selected.cycleName,
                    cyclePluralName: this.selected.cyclePluralName,
                    hasStartOfShiftSpecialTasks: this.selected.hasStartOfShiftSpecialTasks,
                    startOfShiftTaskTypes: this.selected.startOfShiftTaskTypesModel,
                    hasEndOfShiftSpecialTask: this.selected.hasEndOfShiftSpecialTask,
                    endOfShiftTaskTypeId: this.selected.endOfShiftTaskType,
                    allowDevelopmentManualTasks: this.selected.allowDevelopmentManualTasks
                });
            } else {
                await Departments.update({
                    _type: 'UpdateDepartmentCommand',
                    id: this.selected.id,
                    name: this.selected.name,
                    locationName: this.selected.locationName,
                    locationPluralName: this.selected.locationPluralName,
                    locationAbbrName: this.selected.locationAbbrName,
                    cycleName: this.selected.cycleName,
                    cyclePluralName: this.selected.cyclePluralName,
                    hasStartOfShiftSpecialTasks: this.selected.hasStartOfShiftSpecialTasks,
                    startOfShiftTaskTypes: this.selected.startOfShiftTaskTypesModel,
                    hasEndOfShiftSpecialTask: this.selected.hasEndOfShiftSpecialTask,
                    endOfShiftTaskTypeId: this.selected.endOfShiftTaskType,
                    allowDevelopmentManualTasks: this.selected.allowDevelopmentManualTasks
                });
            }

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Department')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Department'), {})) {
                await Departments.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Department')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        }
    },
    computed: {
        filtered() {
            if (!this.data || !this.selectedMineArea) {
                return [];
            }
            return this.data.filter(x => x.mineAreaId == this.selectedMineArea);
        },
        filteredTasks() {
            if (!this.taskTypes || !this.selected) {
                return [];
            }
            return this.taskTypes.filter(x => x.departmentId == this.selected.id);
        },
        filteredTasksForEoSTask() {
            return this.filteredTasks.filter(x=>x.taskClass === TaskClasses.Fire ||
                x.id === this.selected.endOfShiftTaskType);
        }
    }
};
</script>
